import React from "react";

import CardConvert from "./components/card-convert";
import StepClaimStaking from "./components/step-claim";
import BlockButtonStake from "./components/block-button";
import CardDescriptionStake from "./components/card-description";

import logo from "../web3/assets/logo-web3.png";
import EthSvg from "./components/eth-svg";

const StakingPage = () => {
  return (
    <div className="max-w-[500px] m-auto bg-white min-h-[100vh] p-3 pb-6">
      <div className="flex items-center justify-between gap-x-5">
        <h4 className="text-black text-xl font-semibold max-w-[280px]">
          Kiếm lợi nhuận hàng ngày mà vẫn có thể sử dụng token đã stake
        </h4>

        {/* <img src={logo} alt="logo" className="w-14 h-14" /> */}

        <EthSvg width="50px" height="50px" />
      </div>

      <CardConvert />

      <BlockButtonStake />

      <StepClaimStaking />

      <CardDescriptionStake />
    </div>
  );
};

export default StakingPage;
