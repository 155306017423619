/* eslint-disable implicit-arrow-linebreak */
import AccumulatePage from "modules/ego-system/accumulate";
import OrderHistoryAccumulateAdmin from "modules/ego-system/accumulate/admin/page/order-accumulate";
import ProgramAccumulate from "modules/ego-system/accumulate/admin/page/program-accumulate";
import AccumulateDetailPage from "modules/ego-system/accumulate/page-details";
import OrderHistoryAccumulatePage from "modules/ego-system/accumulate/page-order-history";
import StakingPage from "modules/ego-system/staking";
import PageEthStake from "modules/ego-system/staking/page-staking-eth";
import Web3EgoPage from "modules/ego-system/web3/clients";
import OwnerDetail from "modules/invest/client/pages/owner-detail";
import AccountInvestDetailPage from "modules/invest/invest-account/components/account-detail";
import AuthPaymentPage from "modules/invest/invest-auth-payment";
import OrderHistoryPageAdmin from "modules/invest/invest-order-history";
import UnitInvestDetailPage from "modules/invest/invest-unit/unit-detail";
import { lazy } from "react";
import { KEYS } from "utility/permission/key-config";

const InvestUnitPage = lazy(() => import("modules/invest/invest-unit"));
const InvestAccount = lazy(() => import("modules/invest/invest-account"));
const InvestCampaign = lazy(() => import("modules/invest/invest-campaign"));

const InvestPage = lazy(() => import("modules/invest/client"));
const InvestPayment = lazy(() => import("modules/invest/client/pages/payment"));
const OrderHistoryPage = lazy(() =>
  import("modules/invest/client/pages/order-history")
);
const InvestDetailPage = lazy(() =>
  import("modules/invest/client/pages/campaign-detail")
);
const InvestCampaignsPage = lazy(() =>
  import("modules/invest/client/pages/campaign-list")
);

export const INVEST_ROUTES = {
  UNIT: "/invest/unit",
  UNIT_DETAIL: "/invest/unit-detail/:id",

  ACCOUNT: "/invest/account",
  ACCOUNT_DETAIL: "/invest/account-detail/:id",

  INVEST_CAMPAIGN: "/invest/campaign",
  INVEST_CAMPAIGN_DETAIL: "/invest/campaign-detail/:id",

  INVEST_AUTHENTICATE_PAYMENT: "/invest/authenticate-payment",
  // INVEST_ORDER_HISTORY_ADMIN: "/invest/order-history-manage",

  INVEST_HISTORY: "/invest/history",

  INVEST_WEBVIEW: "/invest/webview",
  INVEST_OWNER_DETAIL: "/invest/webview-owner-detail/:id",

  INVEST_CAMPAIGN_LIST: "/invest/campaign-list",
  INVEST_DETAIL_WEBVIEW: "/invest-detail/webview/:id",
  PAYMENT: "/invest-payment",
  INVEST_ORDER_HISTORY: "/invest/order-history",
  INVEST_ORDER_HISTORY_ADMIN: "/invest/order-history-admin",

  WEB3_EGO: "/webview/web3-ego",
  STAKING_EGO: "/webview/staking-ego",
  STAKING_ETH: "/webview/staking-eth",

  ACCUMULATE: "/webview/accumulate",
  ACCUMULATE_DETAIL: "/webview/accumulate-details",
  ACCUMULATE_PAYMENT: "/webview/accumulate-payment",

  ACCUMULATE_PROGRAM: "/accumulate-program",
  ACCUMULATE_PROGRAM_ORDER: "/accumulate-program-order",
};

export const investRoutes = [
  {
    path: INVEST_ROUTES.ACCOUNT,
    element: <InvestAccount />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_ACCOUNT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.ACCOUNT_DETAIL,
    element: <AccountInvestDetailPage />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_ACCOUNT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_CAMPAIGN,
    element: <InvestCampaign />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_ACCOUNT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.UNIT,
    element: <InvestUnitPage />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_UNIT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.UNIT_DETAIL,
    element: <UnitInvestDetailPage />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_UNIT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_AUTHENTICATE_PAYMENT,
    element: <AuthPaymentPage />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_AUTH_PAYMENT,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_ORDER_HISTORY_ADMIN,
    element: <OrderHistoryPageAdmin />,
    meta: {
      permissionKey: KEYS.INVEST_MANAGER.INVEST_HISTORY_ADMIN,
      isDevelop: false,
      isMaintain: false,
    },
  },

  {
    path: INVEST_ROUTES.ACCUMULATE_PAYMENT,
    element: <OrderHistoryAccumulatePage />,
    meta: {
      permissionKey: KEYS.WEBVIEW.ACCUMULATE__PAYMENT_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.ACCUMULATE_PROGRAM,
    element: <ProgramAccumulate />,
    meta: {
      permissionKey: KEYS.EGO.ACCUMULATE_PROGRAM,
    },
  },

  {
    path: INVEST_ROUTES.ACCUMULATE_PROGRAM_ORDER,
    element: <OrderHistoryAccumulateAdmin />,
    meta: {
      permissionKey: KEYS.EGO.ACCUMULATE_PROGRAM_ORDER,
    },
  },
];

export const investWebviewRoutes = [
  {
    path: INVEST_ROUTES.INVEST_WEBVIEW,
    element: <InvestPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.INVEST_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_OWNER_DETAIL,
    element: <OwnerDetail />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.INVEST_OWNER_DETAIL_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_DETAIL_WEBVIEW,
    element: <InvestDetailPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.INVEST_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.PAYMENT,
    element: <InvestPayment />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.PAYMENT_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_ORDER_HISTORY,
    element: <OrderHistoryPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.PAYMENT_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.INVEST_CAMPAIGN_LIST,
    element: <InvestCampaignsPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.PAYMENT_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.WEB3_EGO,
    element: <Web3EgoPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.EGO_WEB3,
    },
  },

  {
    path: INVEST_ROUTES.STAKING_EGO,
    element: <StakingPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.STAKING_WEB3,
    },
  },

  {
    path: INVEST_ROUTES.STAKING_ETH,
    element: <PageEthStake />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.STAKING_ETH,
    },
  },

  {
    path: INVEST_ROUTES.ACCUMULATE,
    element: <AccumulatePage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.ACCUMULATE_PAGE,
    },
  },

  {
    path: INVEST_ROUTES.ACCUMULATE_DETAIL,
    element: <AccumulateDetailPage />,
    meta: {
      layout: "blank",
      permissionKey: KEYS.WEBVIEW.ACCUMULATE__DETAIL_PAGE,
    },
  },
];

export const investAllRoutes = investRoutes.concat(investWebviewRoutes);
