
import { getOneInvestUnit } from "modules/invest/stores";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dispatch } from "root-stores";
import TabOwnerDetail from "../../components/tab-owner-detail";
import "./index.scss";

const OwnerDetail = () => {
  const params = useParams();
  const { id } = params;

  const { unit } = useSelector(state => state.invest);

  useLayoutEffect(() => {
    if (id) {
      dispatch(getOneInvestUnit(id));
    }
  }, [id])

  return (
    <div className="bg-white max-w-[700px] w-full m-auto min-h-screen min-h-dvh px-3 py-4 pb-8 owner-invest-detail space-y-4">
      <h3 className="text-primary font-bold">Thông tin chủ đầu tư</h3>

      <div className="space-y-2">
        <div className="grid grid-cols-4 gap-x-4">
          <img src={unit?.logo} className="w-full aspect-square object-cover rounded-md border-1" />

          <div className="space-y-1 col-span-3">
            <span className="text-sm text-blue-950 font-medium line-clamp-1">{unit?.name}</span>

            <div className="flex items-center gap-x-2">
              <span className="text-secondary-2 text-sm">Người đại diện:</span>

              <span className="text-sm text-blue-950">{unit?.nameAgent}</span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-secondary-2 text-sm">Mã số thuế:</span>
              <span className="text-sm text-blue-950">{unit?.taxCode}</span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-secondary-2 text-sm">Địa chỉ:</span>
              <span className="text-sm text-blue-950 line-clamp-2">{unit?.address}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 text-black">
        <TabOwnerDetail unitId={id} />
      </div>
    </div>
  );
};

export default OwnerDetail;
