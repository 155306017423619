/* eslint-disable multiline-ternary */
import { ACCOUNT_BUSINESS_TYPE, LOCAL_STORAGE, ROLE } from "constants";
import { AUTH_ROUTES } from "router/ego-admin-routes/auth";
import { NOTIFICATION_ROUTER } from "router/ego-admin-routes/notification";
import { INVEST_ROUTES } from "router/invest-routes";
import LocalUtils from "./storage";
import { SUPPER_ADMIN } from "router/supper-admin-routes";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * @description format phone number
 */
export const formatPhoneNumberToShow = (phone, code = "84") => {
  const regex = new RegExp(`^\\+${code}`);
  return phone?.replace(regex, "0");
};

export const formatPhoneNumberToServer = (phone, code = "+84") => {
  return phone?.replace(/^0/, code);
};

export const formatPhoneNumberToServerLaos = (phone, code = "+856") => {
  return phone?.replace(/^0/, code);
};

// ** Returns K format from a number
// eslint-disable-next-line no-confusing-arrow
export const kFormatter = (num) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Generate key
export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" },
  timeZone = "en-US"
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat(timeZone, formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 */
export const isUserLoggedIn = () => LocalUtils.get(LOCAL_STORAGE.USER_DATA);
export const getUserData = () => {
  try {
    const userData = JSON.parse(LocalUtils.get(LOCAL_STORAGE.USER_DATA));

    if (userData) {
      return userData;
    }

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * The function is used for check user role permission
 * @param {String} userRole role of user
 * @param {Array<String>} permissionRoles
 */
export const checkRolePermissionRoutes = (userRole, permissionRoles = []) => {
  return permissionRoles.includes(userRole);
};

/**
 * @description function used for check business type for partner
 */
export const checkBusinessTypePermissionRoutes = (
  userInfo,
  routeMetaOptions
) => {
  if (userInfo.role !== ROLE.PARTNER) {
    return true;
  }
  const businessTypeKey = (() => {
    switch (userInfo.partnerTypes?.[0]) {
      case ACCOUNT_BUSINESS_TYPE.TRANSPORTATION.VALUE:
        return "transportationTypes";

      case ACCOUNT_BUSINESS_TYPE.SHOPPING.VALUE:
        return "shoppingTypes";

      case ACCOUNT_BUSINESS_TYPE.TRAVEL.VALUE:
        return "travelTypes";

      default:
        return "";
    }
  })();

  if (!businessTypeKey) {
    return false;
  }

  const types = userInfo?.[businessTypeKey] ?? [];

  const permissionTypes =
    routeMetaOptions?.permissionTypes?.[userInfo.partnerTypes?.[0]] ?? [];
  return types.some((type) => permissionTypes.includes(type));
};

export const getPermissionByBusinessType = () => {};

/**
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (user) => {
  if (user) {
    if (user.role === ROLE.SUPER_ADMIN) {
      return SUPPER_ADMIN.ADMIN_ACCOUNT;
    }

    if (user.role === ROLE.INVEST_MANAGER) {
      return INVEST_ROUTES.ACCOUNT;
    }

    if (user.role === ROLE.CLIENT) {
      return INVEST_ROUTES.INVEST_WEBVIEW;
    }

    return NOTIFICATION_ROUTER.NOTICE;
  }

  // if (user.role === ROLE.EGO_PARTNERS_MANAGER) {
  //   return NOTIFICATION_ROUTER.NOTICE;
  // }

  // if (user.role === ROLE.SUPPORT_MANAGER) return USER_ROUTES.USER;

  // if (user.role === ROLE.GENERAL_MANAGER) return LOCATION_ROUTES.LOCATION;

  // if (user.role === ROLE.BOOKING_MANAGER) return MAP_ROUTES.MAIN;

  // if (user.role === ROLE.SWITCHBOARD_OPERATOR)
  //   // eslint-disable-next-line nonblock-statement-body-position
  //   return BOOKING_ROUTES.CREATE_BOOKING;

  // if (
  //   user.role === ROLE.PARTNER ||
  //   user.role === ROLE.PARTNER_SHOP ||
  //   user.role === ROLE.PARTNER_SUPPORT
  // ) {
  //   if (user.partnerTypes?.[0] === ACCOUNT_BUSINESS_TYPE.TRANSPORTATION.VALUE) {
  //     if (
  //       user.transportationTypes.includes(
  //         TRANSPORTATION_BUSINESS_TYPES.TAXI.VALUE
  //       )
  //     ) {
  //       return PARTNER_ROUTES.BOOKING.TAXI;
  //     }

  //     if (
  //       user.transportationTypes.includes(
  //         TRANSPORTATION_BUSINESS_TYPES.CAR_RENTAL.VALUE
  //       )
  //     ) {
  //       return PARTNER_ROUTES.BOOKING.RENTAL_BOOKING;
  //     }

  //     if (
  //       user.transportationTypes.includes(
  //         TRANSPORTATION_BUSINESS_TYPES.BUS_TICKET_SALES.VALUE
  //       )
  //     ) {
  //       return PARTNER_ROUTES.BOOKING.BUS_BOOKING;
  //     }

  //     if (
  //       user.transportationTypes.includes(
  //         TRANSPORTATION_BUSINESS_TYPES.CARGO_TRANS.VALUE
  //       )
  //     ) {
  //       return PARTNER_ROUTES.SETTING.INFO;
  //     }
  //   }

  //   if (user.partnerTypes?.[0] === ACCOUNT_BUSINESS_TYPE.SHOPPING.VALUE) {
  //     return PARTNER_ROUTES.SETTING.INFO;
  //   }

  //   if (user.partnerTypes?.[0] === ACCOUNT_BUSINESS_TYPE.TRAVEL.VALUE) {
  //     return PARTNER_ROUTES.SETTING.INFO;
  //   }

  //   return PARTNER_ROUTES.SETTING.INFO;
  // }

  // if (user?.role === ROLE.PARTNER_STAFF) {
  //   if (
  //     user.typePartnerStaff === ROLE_STAFF_PARTNER?.CEO?.value ||
  //     user.typePartnerStaff === ROLE_STAFF_PARTNER?.BIZ_MGR?.value ||
  //     user.typePartnerStaff === ROLE_STAFF_PARTNER?.FIN_HR_MGR?.value
  //   ) {
  //     return PARTNER_ROUTES.DASHBOARD;
  //   }

  //   if (user.typePartnerStaff === ROLE_STAFF_PARTNER?.FLEET_MGR?.value) {
  //     return PARTNER_ROUTES.CAR_DRIVER.CAR;
  //   }

  //   if (user.typePartnerStaff === ROLE_STAFF_PARTNER?.TECHNICIAN?.value) {
  //     return PARTNER_ROUTES.BOOKING.TAXI;
  //   }
  // }

  return AUTH_ROUTES.LOGIN;
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export function filterObject(obj) {
  let filteredObj = {};
  for (let key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== undefined
    ) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

export const parseDataFromLocalStorage = (storageData) => {
  try {
    const data = JSON.parse(storageData);
    return data;
  } catch (error) {
    return null;
  }
};
