import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

export const AUTH_FIELDS = gql`
  fragment AuthFields on UserClient {
    id
    key
    name
    role
    phone
    email
    avatar
    address
    username
    location
    isActived
    createdAt
    updatedAt
    workPlaceId
    carTypeIds
    transportationTypes
    partnerTypes
    shoppingTypes
    travelTypes
    workPlaces {
      name
      id
    }

    workPlace {
      name
      location
      landMarkIds
      isAutoPickDriver
    }

    country {
      id
      name
      currencyUnit
    }
    identityCard
    signInProvider
    businessRules

    partnerTypes
    partnerId
    typePartnerStaff

    carTypes {
      id
      name
    }

    egoToken
    referralCode
    inviteCode
    eKycStatus
    userConnect

    referralUser {
      id
      name
      referralCode
    }
  }
`;

const authAction = {
  login: gql`
    mutation loginUser($username: String!, $password: String!, $type: String!) {
      loginUser(username: $username, password: $password, type: $type) {
        user {
          ...AuthFields
        }

        token
      }
    }
    ${AUTH_FIELDS}
  `,

  getOTP: gql`
    mutation getOTP($phoneNumber: String!) {
      getOTP(phoneNumber: $phoneNumber)
    }
  `,

  confirmOtp: gql`
    mutation confirmOtp($phoneNumber: String!, $code: String!) {
      confirmOtp(phoneNumber: $phoneNumber, code: $code)
    }
  `,

  resetPasswordByPhone: gql`
    mutation resetPasswordByPhone(
      $phoneNumber: String!
      $idToken: String!
      $newPassword: String!
    ) {
      resetPasswordByPhone(
        phoneNumber: $phoneNumber
        idToken: $idToken
        newPassword: $newPassword
      )
    }
  `,

  getAccountInfo: gql`
    query getMe {
      getMe {
        ...AuthFields
        transportationTypes
        wallet {
          balance
        }

        shoppingTypes
        travelTypes
      }
    }
    ${AUTH_FIELDS}
  `,
};

export const authApi = {
  login: (payload) => {
    return mutate("loginUser", authAction.login, payload);
  },

  getOTP: (payload) => {
    return mutate("getOTP", authAction.getOTP, payload);
  },

  confirmOtp: (payload) => {
    return mutate("confirmOtp", authAction.confirmOtp, payload);
  },

  resetPassword: (payload) => {
    return mutate(
      "resetPasswordByPhone",
      authAction.resetPasswordByPhone,
      payload
    );
  },

  getAccountInfo: () => {
    return query("getMe", authAction.getAccountInfo);
  },
};
